import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import difference from "lodash/difference";
import React, { useEffect, useState } from "react";
import { getUser } from "../../services/auth";

const ListDropdown = ({ onChange }) => {
  const data = useStaticQuery(graphql`
    query {
      therapeuticAreaEn: allStrapiTherapeuticalArea(
        filter: { locale: { eq: "en" } }
      ) {
        edges {
          node {
            Name
            Slug
            AlternateSlug
          }
        }
      }
      therapeuticAreaEs: allStrapiTherapeuticalArea(
        filter: { locale: { eq: "es-PA" } }
      ) {
        edges {
          node {
            Name
            Slug
            AlternateSlug
          }
        }
      }
    }
  `);
  const [checkedValue, setCheckedValue] = useState([]);
  const { t, language } = useI18next();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === "string" ? value.split(",") : value;
    const diffValue = difference(newValue, checkedValue);
    if (diffValue.length > 0) {
      window.dataLayer?.push({
        event: "click_filter_content",
        filter_name: diffValue[0],
      });
    }
    setCheckedValue(newValue);
    onChange(newValue);
  };
  const therapeuticAreas =
    language === "en"
      ? data.therapeuticAreaEn.edges
      : data.therapeuticAreaEs.edges;
  const [therapeuticAreaOptions, setTherapeuticAreaOptions] = useState(
    therapeuticAreas.map((th) => {
      return {
        id: th.node.Slug,
        content: th.node.Name,
        alternateSlug: th.node.AlternateSlug,
      };
    })
  );
  const theme = useTheme();
  const user = getUser();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const areasOfExpertise =
      language === "en"
        ? therapeuticAreaOptions
            .filter((t) =>
              JSON.parse(user.areasOfExpertise ?? "[]").includes(t.id)
            )
            .map((t) => t.content)
        : therapeuticAreaOptions
            .filter((t) =>
              JSON.parse(user.areasOfExpertise ?? "[]").includes(
                t.alternateSlug
              )
            )
            .map((t) => t.content);
    setCheckedValue(areasOfExpertise);
    onChange(areasOfExpertise);
    setTherapeuticAreaOptions(
      therapeuticAreas.map((th) => {
        return {
          id: th.node.Slug,
          content: th.node.Name,
          alternateSlug: th.node.AlternateSlug,
        };
      })
    );
  }, [therapeuticAreas]);

  return (
    <Container fixed sx={{ mt: 4 }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "flex-end",
        }}
      >
        <Grid
          item
          xs={12}
          xl={4}
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
          }}
        >
          <FormControl sx={{ width: 280 }} variant="standard">
            <InputLabel id="filter-content-label">
              {t("chooseCategory")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={checkedValue}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
            >
              {therapeuticAreaOptions.map((item) => (
                <MenuItem key={item.id} value={item.content}>
                  <Checkbox checked={checkedValue.indexOf(item.content) > -1} />
                  <ListItemText primary={item.content} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ListDropdown;
