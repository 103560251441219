import { Container, Typography } from "@mui/material";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";

const NoResult = () => {
  const { t } = useI18next();
  return (
    <Container
      maxWidth="false"
      sx={{
        bgcolor: "#FFFFFF",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          py: 8,
          gap: 8,
        }}
        style={{ marginTop: 20 }}
      >
        <Typography>{t("noResult")}</Typography>
      </Container>
    </Container>
  );
};

export default NoResult;
