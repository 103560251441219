import React from "react";
import { isLoggedIn } from "../services/auth";
import LoginBlock from "./login-block";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn()) {
    return <LoginBlock />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
